import { DeliveryAddress } from 'src/types/customer/address';
import { Payment } from 'src/types/offer/Payment';
import { CrossSellInfo } from 'src/types/product/CrossSell';
import { ExtraServiceVoucher, ServiceVoucher } from 'src/types/voucher/ServiceVoucher';


export enum EditOrderActionType {
  ADD_POSITION = 'ADD_POSITION',
  REMOVE_POSITION = 'REMOVE_POSITION',
  UPDATE_POSITION = 'UPDATE_POSITION',
  CANCEL_POSITION = 'CANCEL_POSITION',
  UPDATE_POSITION_PRICE_DATE = 'UPDATE_POSITION_PRICE_DATE',
  SWITCH_DELIVERY_ADDRESS = 'SWITCH_DELIVERY_ADDRESS',
  CHANGE_PAYMENT = 'CHANGE_PAYMENT',
  REDEEM_VOUCHER = 'REDEEM_VOUCHER',
  REMOVE_VOUCHER = 'REMOVE_VOUCHER',
  UPDATE_WEEE_TAKE_BACK = 'UPDATE_WEEE_TAKE_BACK',
  UPDATE_CUSTOMER = 'UPDATE_CUSTOMER',
  SUBMIT = 'SUBMIT',
  CHANGE_SERVICE_VOUCHER = 'CHANGE_SERVICE_VOUCHER',
}

export interface EditOrderAddActionRequest {
  id?: string;
  type: EditOrderActionType.ADD_POSITION;
  sku: string;
  quantity: number;
  crossSell?: CrossSellInfo
}

export interface EditOrderRemoveActionRequest {
  type: EditOrderActionType.REMOVE_POSITION;
  id: string;
}

export interface EditOrderUpdateActionRequest {
  type: EditOrderActionType.UPDATE_POSITION;
  id: string;
  quantity: number;
  crossSell?: CrossSellInfo
}

export interface EditOrderWeeTakeBackActionRequest {
  type: EditOrderActionType.UPDATE_WEEE_TAKE_BACK;
  id: string;
  enabled: boolean;
  number: string;
}

export interface EditOrderCancelActionRequest {
  type: EditOrderActionType.CANCEL_POSITION;
  id: string;
  reason: string;
}

export interface EditOrderUpdatePositionPriceDateActionRequest {
  type: EditOrderActionType.UPDATE_POSITION_PRICE_DATE;
  id: string;
  priceDate: string;
}

export interface EditOrderSwitchDeliveryAddressActionRequest {
  type: EditOrderActionType.SWITCH_DELIVERY_ADDRESS;
  address: DeliveryAddress;
}
export interface EditOrderChangePaymentActionRequest {
  type: EditOrderActionType.CHANGE_PAYMENT;
  payment: Payment;
}

export interface EditOrderRedeemVoucherActionRequest {
  type: EditOrderActionType.REDEEM_VOUCHER;
  code: string;
}
export interface EditOrderRemoveVoucherActionRequest {
  type: EditOrderActionType.REMOVE_VOUCHER;
  code: string;
}
export interface EditOrderUpdateCustomerActionRequest {
  type: EditOrderActionType.UPDATE_CUSTOMER;
  email?: string;
  phone?: string;
}
export interface EditOrderSubmitActionRequest {
  type: EditOrderActionType.SUBMIT;
  customerUpdate?: EditOrderUpdateCustomerActionRequest;
}

export interface EditOrderChangeServiceVoucherActionRequest {
  type: EditOrderActionType.CHANGE_SERVICE_VOUCHER;
  service?: ServiceVoucher | ExtraServiceVoucher;
}

export type EditOrderActionRequest =
  | EditOrderAddActionRequest
  | EditOrderRemoveActionRequest
  | EditOrderUpdateActionRequest
  | EditOrderWeeTakeBackActionRequest
  | EditOrderCancelActionRequest
  | EditOrderUpdatePositionPriceDateActionRequest
  | EditOrderSwitchDeliveryAddressActionRequest
  | EditOrderChangePaymentActionRequest
  | EditOrderRedeemVoucherActionRequest
  | EditOrderRemoveVoucherActionRequest
  | EditOrderUpdateCustomerActionRequest
  | EditOrderSubmitActionRequest
  | EditOrderChangeServiceVoucherActionRequest

export interface EditOrderActionRequestWithOrderId {
  orderId: string;
  actionRequest: EditOrderActionRequest
}



import { ProcessingSystem } from 'src/api/orderTypes';
import { DeliveryAddress, PostalDeliveryAddress } from 'src/types/customer/address';
import { Customer } from 'src/types/customer/customer';
import { Dimension } from 'src/types/offer/Basket';
import { Currency } from 'src/types/product/Currency';
import { DeliveryTimeMessage } from 'src/types/product/DeliveryTime';
import { MediaUri } from 'src/types/product/Media';
import { ServiceVoucher } from 'src/types/voucher/ServiceVoucher';


export interface OrderHistoryResponse {
  id: string;
  number: string;
  customerId: string;
  placedAt?: string;
  subtotal?: number;
  shippingCosts?: number;
  total: number;
  currency: Currency;
  items: OrderHistoryItem[];
  deliveryAddress?: DeliveryAddress;
  disChannel?: string;
  isCancelable: boolean;
  externalCustomerId?: string;
  customer?: Customer;
  billingAddress?: PostalDeliveryAddress;
  voucher?: OrderVoucher;
  payment?: OrderPayment;
  isEditable?: boolean;
  createdBy?: string;
  response?: string;
  weetakebackEnabled?: boolean;
  orderStatus?: OrderStatus;
  processingSystem?: ProcessingSystem;
  hasBrochureItem?: boolean;
  pregeneratedErpNumber?: number;
  reklaFlag?: ReklaFlag;
  linkedReklaOrderNumber?: string;
}

export interface SearchOrderResult {
  externalCustomerId: string;
}

export interface OrderVoucher {
  code?: string;
  value?: number;
  service?: ServiceVoucher;
  employerDiscount?: number;
}

export interface OrderPayment {
  type: string;
  description?: string;
  dueDay?: number;
  interestRate?: number;
  numOfInst?: number;
}

export interface OrderHistoryItem {
  id?: string;
  originalShopOrderId?: string;
  brand?: string;
  title?: string;
  baseProductNumber: string;
  sku: string;
  singlePrice: number;
  quantity: number;
  priceDate?: string;
  total: number;
  variants: Dimension[];
  mediaUris: MediaUri[];
  trackingId?: string;
  deliveryAddress: DeliveryAddress;
  shopOrderId?: string;
  upsellIndicator?: string;
  deliveryTime?: DeliveryTimeMessage;
  cancellationReasonCode?: string;
  cancellationReasonDescription?: string;
  status?: string;
  voucher?: OrderVoucher;
  voucherDiscountValue?: number;
  createdAt?: string;
  salesOffice?: string;
}

export interface OrderStatus {
  code: OrderStatusEnum;
  label: string;
}

export enum OrderStatusEnum {
  received = 'received',
  processing = 'processing',
  inDelivery = 'inDelivery',
  dispatched = 'dispatched',
  cancelled = 'cancelled',
  returned = 'returned',
  partiallyReturned = 'partiallyReturned'
}

export const UPSELL_ABO_CODE = '007';
export const UPSELL_WAITLIST_CODE = '008';

export interface ReklaFlag{
  originalExternalOrderId?: string,
  isZeroPercent?: boolean,
}

import {
  call,
  getContext,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { ApiCallCenter } from 'src/api/apiCallCenter';
import { logErrorEvent } from 'src/logging/loggingActions';
import { showErrorNotification } from 'src/redux/app/appSlice';
import {
  sendingRingsTemplateLetterFinished,
  sendingRingsTemplateLetterInProgress,
  sendingRingsTemplateLetterSuccessful,
  sendRingsTemplateLetter,
} from 'src/redux/customer/customerInfoSlice';
import { customerInfoSelectors } from 'src/redux/customer/selectors/customerInfoSelectors';
import { SagaContextItem } from 'src/store/ReduxSagaContext';
import { PostalDeliveryAddress } from 'src/types/customer/address';
import { RingsTemplateRequest } from 'src/types/offer/InfoPostRequests';
import { extractDetails, isConflictStatus } from 'src/utils/errorStatusChecks';
import stringify from 'src/utils/stringify';


export function* sendRingsTemplateLetterSaga() {
  const externalCustomerId: string = yield select(customerInfoSelectors.getCustomerExternalId);

  const selectedDeliveryAddress: PostalDeliveryAddress = yield select(customerInfoSelectors.getSelectedDeliveryAddress);

  const request: RingsTemplateRequest = {
    externalCustomerId: externalCustomerId,
    address: selectedDeliveryAddress,
  };

  try {
    yield put(sendingRingsTemplateLetterInProgress());
    const apiCallCenter: ApiCallCenter = yield getContext(SagaContextItem.apiCallCenter);

    yield call(apiCallCenter.sendRingsTemplateLetter, request);
    yield put(sendingRingsTemplateLetterSuccessful());

  } catch (err) {
    yield put(showErrorNotification(extractDetails(err) || 'Hoppla... Fehler beim Senden des Ringschablone-Briefes'));
    if(!isConflictStatus(err)) {
      yield put(logErrorEvent({ message: `Couldn't order Ringschablone for customer ${externalCustomerId}, ${stringify(request)}`, err }));
    }
  } finally {
    yield put(sendingRingsTemplateLetterFinished());
  }
}

export default function* sendRingsTemplateLetterWatcher() {
  yield takeLatest(sendRingsTemplateLetter.type, sendRingsTemplateLetterSaga);
}


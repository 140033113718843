import { RolesNames } from 'src/constants/authorizedRoles';
import { LocalStorageKeys } from 'src/constants/localStorageKeys';
import { RootStateType } from 'src/constants/types';
import countryLocalStorage from 'src/utils/countryLocalStorage';
import { createSelectors } from 'src/utils/state';


export const appSelectors = createSelectors({
  getOpenedModals({ app: { modal } }: RootStateType) {
    return modal.opened;
  },
  getCurrentCountry({ app: { country } }: RootStateType) {
    return country ?? countryLocalStorage.get();
  },
  getJwt({ app: { idToken } }: RootStateType) {
    return idToken ?? localStorage.getItem(LocalStorageKeys.IdToken);
  },
  getIdToken(state: RootStateType) {
    return state.app.idToken;
  },
  getAuthenticated(state: RootStateType) {
    return !!state.app.idToken;
  },
  getRouter(state: RootStateType) {
    return state.router;
  },
  getAgentInfo(state: RootStateType) {
    return state.app.agentInfo;
  },
  getAgentRoles(state: RootStateType) {
    return state.app.agentInfo?.roles || [];
  },
  doesAgentHaveServiceManagementRole(state: RootStateType) {
    return state.app.agentInfo?.roles?.includes(RolesNames.ServiceManagement.toString());
  },
  getAgentUsername(state: RootStateType) {
    return state.app.agentInfo?.username;
  },
  getCountry(state: RootStateType) {
    return state.app.country;
  },
  getLanguage(state: RootStateType) {
    return state.app.language;
  },
  getNotifications(state: RootStateType) {
    return state.app.notifications;
  },
  getAuthError(state: RootStateType) {
    return state.app.authError;
  },
  getLoading(state: RootStateType) {
    return state.app.loading;
  },
  getSnackNotifications(state: RootStateType) {
    return state.app.snackNotifications;
  },
  getLoginMethod(state: RootStateType){
    return state.app.loginMethod;
  }
});

export default {
  'orderHistory.from': 'From',
  'orderHistory.to': 'To',
  'orderHistory.apply': 'Apply',
  'orderHistory.cancel': 'Cancel',

  'orderHistory.last14Days': 'Last 14 Days',
  'orderHistory.lastMonth': 'This Month',
  'orderHistory.lastYear': 'This Year',
  'orderHistory.customRange': 'Custom Range',
  'orderHistory.orderNumber': 'Order Number',
  'orderHistory.orderDate': 'Order Date',
  'orderHistory.deliveryStatus': 'Delivery Status',
  'orderHistory.source': 'Source Information',
  'orderHistory.paymentMethod': 'Payment Method',
  'orderHistory.shippingDelay': 'Shipping Delay',
  'orderHistory.totalPrice': 'Total Paid',
  'orderHistory.freeshipping': 'Free Shipping',
  'orderHistory.paymentMethod.PREPAYMENT': 'Prepayment',
  'orderHistory.paymentMethod.PAYPAL': 'Paypal',
  'orderHistory.paymentMethod.DIRECT_DEBIT': 'Direct debit',
  'orderHistory.paymentMethod.INVOICE': 'Invoice',
  'orderHistory.paymentMethod.CASH_ON_DELIVERY': 'Cash on delivery',
  'orderHistory.paymentMethod.CONSUMER_CREDIT': 'Consumer credit',
  'orderHistory.paymentMethod.CREDIT_CARD': 'Credit card',
  'orderHistory.paymentMethod.PARTIAL_PAYMENT': 'Partial payment',
  'orderHistory.paymentMethod.UNKNOWN': 'Unknown',
  'orderHistory.title': 'Order History',
  'orderHistory.summary.subtotal': 'Subtotal price',
  'orderHistory.summary.total': 'Total paid',
  'orderHistory.summary.saving': 'Saved',
  'orderHistory.summary.shipping': 'Shipping cost',
  'orderHistory.summary.voucher': 'Voucher',
  'orderHistory.openButton' : 'Bestellhistorie',
  'orderHistory.feedback.emptyResult' :  'There are no orders yet',
  'orderHistory.billNumber' :  'Rechnungsnummer',
  'orderHistory.noBillNumber' :  'Noch nicht erstellt',
  'orderHistory.billingAddress' :  'Billing Address',
  'orderHistory.deliveryAddress' :  'Delivery Address',
  'orderHistory.shippingAddress' :  'Shipping Address',
  'orderHistory.orderInformations' : 'Order Informations',
  'orderHistory.cancelOrder_modalTitle': 'Cancel Order Confirmation',
  'orderHistory.cancelOrder_modalConfirme': 'Yes',
  'orderHistory.cancelOrder_modalDecline': 'NO',
  'orderHistory.cancelOrder_modalMessage': 'Are you sure you want to cancel this order?',
  'orderHistory.cancelOrder.txtBtn': 'Cancel',
  'orderHistory.NoMoreResults': 'No more results.',

  'orderHistory.back': 'Zurück zur Bestellseite',
  'orderHistory.moreInfo': 'More info',
  'orderHistory.result': 'Bestellhistorie',
  'orderHistory.noResult': 'No {status} orders were found',
  'orderHistory.tabs.allOrders': 'All Orders',
  'orderHistory.tabs.inProgress': 'In Progress',
  'orderHistory.tabs.closed': 'Closed',
  'orderHistory.searchOrder': 'Search by BNR, product, etc',
  'orderHistory.billingInfo': 'Rechnungsinfo',
  'orderHistory.orderInfo': 'order information',
  'orderHistory.deliveryInfo': 'Lieferinfo',
  'orderHistory.multipleDeliveryPosition': 'Lieferadressen stehen in den Positionen',
  'orderHistory.paymentInformation': 'Zahlungsinfo',
  'orderHistory.positions': 'Positionshistorie',
  'orderHistory.priceDetails': 'Details Gesamtbetrag',
  'orderHistory.addToBasket': 'Erneut kaufen',
  'orderHistory.reklaButton': 'Rekla-Auftrag',
  'orderHistory.noTrackingNumber': 'Sendungsnummer ist noch nicht verfügbar',
  'orderHistory.trackingNumber': 'Sendungsnummer',
  'orderHistory.package': 'Paket',
  'orderHistory.carrier': 'Spedition',
  'orderHistory.waitlistEntryDate': 'Wait list entry from {date}',

  'orderHistory.postitionCreatedAt': 'Created on: {date} Uhr',

  'orderHistory.customerNr': 'Kundennummer',
  'orderHistory.email': 'E-Mail-Adresse',
  'orderHistory.salutation': 'Anrede',
  'orderHistory.firstName': 'Vorname',
  'orderHistory.lastName': 'Nachname',
  'orderHistory.dateOfBirth': 'Geburtsdatum',
  'orderHistory.phone': 'Telefonnummer',
  'orderHistory.createdBy': 'createdBy',
  'orderHistory.response': 'Input channel',
  'orderHistory.salutation.MR': 'Herr',
  'orderHistory.salutation.MS': 'Frau',
  'orderHistory.edit.back': 'Zurück zur Bestellhistorie',
  'orderHistory.orderedOn': 'Bestellt am {date}',
  'orderHistory.changesNotSaved': 'Die Änderungen, die Sie vorgenommen haben, werden nicht gespeichert.',
  'orderHistory.editOrder.txtBtn': 'Bearbeiten',
  'orderHistory.deliveryDays': 'Liefertage',
  'orderHistory.days': 'Tage',
  'orderHistory.info': 'Auftragsinfo',

  'orderHistory.brochureHint': 'Brochure "Welcome to HSE24" was sent with this order.',
  'orderHistory.filters.clearAll': 'Alle Filter löschen',
  'orderHistory.salesOffice': '/ Fenster: {salesOffice}',

  'orderHistory.item.rekla.hint': 'Replacement for this item ',
  'orderHistory.item.rekla.hint.withoutOrderNumber': 'without original order.',
  'orderHistory.item.rekla.hint.onOriginalOrder': 'Replacement for this item has been sent. Order number:',

  'orderHistory.voucher.title': '{value} {currency} - Voucher "{code}" is being redeemed',  
};

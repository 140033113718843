import { ReactElement } from 'react';

import {
  StockLevel,
  Variant,
  MediaUri,
} from 'src/types/offer/Basket';
import { CrossSellInfo } from 'src/types/product/CrossSell';
import { DeliveryTimeMessage } from 'src/types/product/DeliveryTime';
import { ProductDetails } from 'src/types/product/product';


export interface OrderItem {
  id: string;
  baseProductNo: string;
  basketId?: string;
  name: {
    long: string;
    short?: string;
  };
  categoryPath?: string,
  brand?: {
    brandName?: string;
  };
  variant: Variant;
  mediaUris: MediaUri[];
  quantity: number;
  sku?: string;
  stockLevel: StockLevel;
  reserved?: boolean;
  isSellable?: boolean;
  status?: OrderItemStatus;
  deliveryTime?: DeliveryTimeMessage;
  priceDate?: Date;
  crossSell?: CrossSellInfo;
  freeShipping?: boolean;
  upgrade?: ProductDetails;
  upgrade2?: ProductDetails;
  availableStockAmount?: number;
  takeBackObligation?: boolean;
  weeeTakeBackEnabled?: boolean;
  voucherDiscountValue?: number;
  number?: string;
  soldOnMedia?: ProductSoldOnMedia[];
}
export interface OrderItemError {
  field: string;
  content?: ReactElement;
}

export enum ProductSoldOnMedia {
  SOCIAL_LIVE_COMMERCE = 'SOCIAL_LIVE_COMMERCE',
}

export enum NotSellableOrderItemReason {
  ERP_PRODUCT_NOT_RELEASED = 'ERP_PRODUCT_NOT_RELEASED',
  ERP_PRODUCT_NOT_IN_SORTIMENT = 'ERP_PRODUCT_NOT_IN_SORTIMENT',
  VARIANT_NOT_AVAILABLE = 'VARIANT_NOT_AVAILABLE',
  SUBSCRIPTION_ONLY = 'SUBSCRIPTION_ONLY',
  NOT_AVAILABLE_IN_COUNTRY = 'NOT_AVAILABLE_IN_COUNTRY',
  OUT_OF_STOCK = 'OUT_OF_STOCK',
  AVAILABLE_QUANTITY_EXCEEDED = 'AVAILABLE_QUANTITY_EXCEEDED',
  PRICE_DATE_NOT_VALID = 'PRICE_DATE_NOT_VALID'
}

interface SellableOrderItem {
  type: 'SELLABLE'
}
export interface NotSellableOrderItem {
  type: 'NOT_SELLABLE'
  reason: NotSellableOrderItemReason
}

export type OrderItemStatus = SellableOrderItem | NotSellableOrderItem

export default {
  'order.complete' : 'Auftrag abschließen',
  'order.confirmation.title': 'Bestellung erfolgreich',
  'order.basketIsEmpty': 'Die Produktliste ist leer',
  'order.stockWarning': 'Produkte anpassen',
  'order.chosePaymentMethod': 'Zahlungsmethode auswählen',
  'order.enterCustomerData': 'Kundendaten eingeben',
  'order.choseDeliveryAddress': 'Lieferadresse auswählen',
  'order.changeInstallmentPlan': 'Ratenplan ändern',
  'order.choosePaymentMethod': 'Zahlungsart auswählen',
  'order.CustomerWithoutEmail': 'Kunde sollte E-Mail haben',
  'order.confirmation.description.text': 'vielen Dank für Ihre Bestellung',
  'order.confirmation.description.deliveryAddress': 'Wir liefern wie immer an die hinterlegte Lieferadresse.',
  'order.confirmation.description.orderId': 'BNR',
  'order.confirmation.description.deliveryTime': 'Die Lieferzeit beträgt ',
  'order.confirmation.description.deliveryTime.workingDays': 'Werktage.',
  'order.confirmation.description.deliveryTime.delay': ' Lieferzeit kann derzeit vereinzelt auch etwas länger dauern.',
  'order.confirmation.description.installmentSEPANotice': ' Sie erhalten in Kürze ein SEPA Mandat für den Bankeinzug, das sie jederzeit widerrufen können. Der Ratenplan wird Ihnen separat zugesendet.',
  'order.confirmation.description.totalInInstallments': 'Der Gesamtbetrag von {total} wird in {installmentCount} von Ihrem Bankkonto abgebucht.',
  'order.confirmation.description.email': 'Wir haben Ihnen eine Auftragsbestätigung mit allen Details an Ihre E-Mail Adresse gesendet.',
  'order.confirmation.description.regards': 'Ich wünsche Ihnen noch einen schönen Tag. Auf Wiederhören!',
  'order.confirmation.message.INVOICE': 'Den Rechnungsbetrag <b>überweisen Sie selbst</b>.',
  'order.confirmation.message.DIRECT_DEBIT': 'Den Rechnungsbetrag buchen wir <b>5 Tage nach Versand der Ware</b> von Ihrem Bankkonto ab.',
  'order.confirmation.message.CASH_ON_DELIVERY': 'Den Rechnungsbetrag zahlen Sie per <b>Nachnahme, bei Empfang der Ware</b>.',
  'order.confirmation.message.DE_CASH_ON_DELIVERY': 'Bitte halten Sie 5,99 Euro zusätzlich als Nachnahme-Gebühr bereit.',
  'order.confirmation.message.AT_CASH_ON_DELIVERY': 'Bitte halten Sie 4,50 Euro zusätzlich als Nachnahme-Gebühr bereit',
  'order.close': 'Neue Sitzung',
  'order.confirmation.takeBack' : 'Alt-Elektrogeräteabholung',
  'order.confirmation.mandat.directdebit': 'Sie erhalten in Kürze ein SEPA-Mandat für Ihren Bankeinzug. Bitte prüfen Sie die darin enthaltenen Angaben. Sie können Ihr SEPA-Mandat jederzeit widerrufen, ein Anruf genügt.',

  'order.payment.required': 'Zahlungsmethode erforderlich',
  'order.create.error': 'Die Bestellung kann nicht erstellt werden. Bitte versuchen Sie es erneut',
  'order.invalid.dateOfBirth': 'Ungültiges Geburtsdatum',
  'order.invalid.paymentMethod': 'Ungültige Zahlungsmethode',
  'order.invalid.order': 'Ungültige Reihenfolge',
  'order.invalid.billingAddress': 'Ungültige Rechnungsadresse',
  'order.invalid.customerData': 'Ungültige Kundendaten',
  'order.cart.isEmpty': 'Ihr Warenkorb ist leer.',

  'order.summary.subtotal': 'Zwischensumme',
  'order.summary.total': 'Gesamtsumme',
  'order.summary.saving': 'Ersparnis',
  'order.summary.shipping': 'Versand',
  'order.summary.voucher': 'Wertgutschein',

  'order.voucher.error': 'Ungültiger Gutschein',
  'order.voucher.submited': 'Gutschein eingelöst',
  'order.voucher.redeemed': ' eingelöst',
  'order.voucher.redeemedWithCode': ' Gutschein "{VOUCHER_CODE}" eingelöst.',
  'order.voucher.restrictedVoucherRedeemed': ' Gutschein "{VOUCHER_CODE}" eingelöst für {RESTRICTIONS}.',
  'order.voucher.freeShippingVoucherReddemed': 'Gutschein "{VOUCHER_CODE}" eingelöst (Versandkostenfrei).',
  'order.voucher.eligibleProducts': 'berechtigte Produkte im Warenkorb',
  'order.voucher.CATEGORY': 'Kategorie',
  'order.voucher.PRODUCT_PRICE_LABEL': 'Preis-Label',
  'order.voucher.BRAND': 'Marke',

  'order.limit': 'Es können nicht mehr als 9 Elemente hinzugefügt werden',

  // ORDER_RESTRICTION
  'order.orderRestriction.CREDIT_LIMIT': 'Kreditlimit',
  'order.orderRestriction.DUNNING_LEVEL_1': 'Mahnstufe 1',
  'order.orderRestriction.CREDIT_CARD': 'Kreditkarte',
  'order.orderRestriction.INFODATA_INQUIRY_4': 'INFODATA_INQUIRY_4',
  'order.orderRestriction.NEGATIVE_INQUIRY': 'Negativauskunft',
  'order.orderRestriction.FINANCING': 'Finanzierung',
  'order.orderRestriction.INFODATA_CREDIT_LIMIT': 'Infodata und Kreditlimit',
  'order.orderRestriction.BIG_INQUIRY': 'Großauskunft',
  'order.orderRestriction.INFODATA_INQUIRY_9': 'Infodata Auskunft',
  'order.orderRestriction.ADDRESS_CHECK_MISSING': 'Bestellung nicht möglich (Adressprüfung notwendig) - Kunde kann sich bei Rückfragen schriftlich an HSE24 wenden',
  'order.orderRestriction.RESERVATION': 'Reservierung',
  'order.orderRestriction.PAYMENT_LIMIT_MODAL_TITLE': 'Bitte Bestellung prüfen',
  'order.orderRestriction.PAYMENT_LIMIT_MODAL_MESSAGE': 'Das Bestelllimit bei {payment_method} ist {amount}. \n\n Bitte versuchen Sie mit einer anderen Zahlungsmethode oder reduzieren Sie die Bestellsumme.',
  'order.orderRestriction.FREQUENT_RETURNER': 'Hochretournierer',
  'order.orderRestriction.ACCOUNT_NUMBER_VALIDATED': 'Kontonummer validiert (15)',
  'order.orderRestriction.DUBIOUS_BANK_ACCOUNTS': 'Dubiose Bankdaten',
  'order.orderRestriction.NON_PAYER_KSV': 'Nichtzahler KSV',
  'order.orderRestriction.NON_PAYER_REAL': 'Nichtzahler Real',
  'order.orderRestriction.NON_PAYER_KSP': 'Nichtzahler KSP',
  'order.orderRestriction.DEPARTED_CREATOR': 'Creator ausgeschieden',
  'order.orderRestriction.LS18': 'Mögliche Kundendublette',
  'order.orderRestriction.LS_INTERN': 'LS intern',
  'order.orderRestriction.DUPLICATE': 'Bitte aktive Kundennummer suchen und nutzen - diese ist eine Dublette (kein Ergebnis: Kunde kann sich schriftlich an HSE wenden)',
  'order.orderRestriction.PERMANANT_RETURNER': 'Hochretournierer',
  'order.orderRestriction.NON_PAYERU': 'Nichtzahler',
  'order.orderRestriction.FRAUD': 'Versandhandelbetrüger',
  'order.orderRestriction.CUSTOMER_REQUIREMENT': 'Bestellung nicht möglich (Auftragsbeschränkung auf Kundenwunsch) - Kunde kann sich zur Freischaltung schriftlich an HSE24 wenden',
  'order.orderRestriction.PRANK_ORDER': 'Sperre Juxbesteller',
  'order.orderRestriction.SAP_LEGACY_DATA': 'Altdaten vor SAP',
  'order.orderRestriction.DECEASED': 'Verstorben',
  'order.orderRestriction.UNKNOWN_ADDRESS': 'Adresse unbekannt',
  'order.orderRestriction.UNDERAGED': 'Bestellung nicht möglich (Auftragsbeschränkung Minderjährig) - Kunde kann sich nach Erreichen der Volljährigkeit gerne melden',
  'order.orderRestriction.DISABILITY': 'Unmündig',
  'order.orderRestriction.DRM_LOCK': 'DRM Sperre',
  'order.orderRestriction.CUSTOMER_TO_BE_DELETED': 'Kunde wird gelöscht',
  'order.orderRestriction.AS_INTERNAL': 'AS intern',
  'order.orderRestriction.SUBJECT_TO_LAWYER': 'An Anwalt abgegeben',
  'order.orderRestriction.INSOLVENCY': 'Raten/Insolvenz/Konkurs',
  'order.orderRestriction.DEPARTED_EMPLOYEE': 'Mitarbeiter ausgeschieden',
  'order.orderRestriction.SEE_MASTER_DATA': 's. Bemerkung Stammsatz',
  'order.orderRestriction.DATA_PRIVACY_LOCK': 'Datenschutzsperrung',
  'order.orderRestriction.DELETION_GDPR': 'Datenlöschung vorgesehen - Bitte Neukundenanlage anbieten',
  'order.orderRestriction.TO_BE_ARCHIVED': 'Archivierung geplant',
  'order.orderRestriction.ARCHIVED': 'Archiviert (EOB)',
  'order.orderRestriction.NON_PAYER_INFOSCORE': 'sperre Nichtzahler ',
  'order.orderRestriction.SIEBEL_APPROVED': 'Freigegeben Siebel',
  'order.orderRestriction.TEMPORARY_DELIVERY_BLOCK': 'Temporäre Liefersperre',

  'customerSearch.orderRestriction.CREDIT_LIMIT': 'Kreditlimit',
  'customerSearch.orderRestriction.DUNNING_LEVEL_1': 'Mahnstufe 1',
  'customerSearch.orderRestriction.CREDIT_CARD': 'Kreditkarte',
  'customerSearch.orderRestriction.INFODATA_INQUIRY_4': 'INFODATA_INQUIRY_4',
  'customerSearch.orderRestriction.NEGATIVE_INQUIRY': 'Negativauskunft',
  'customerSearch.orderRestriction.FINANCING': 'Finanzierung',
  'customerSearch.orderRestriction.INFODATA_CREDIT_LIMIT': 'Infodata und Kreditlimit',
  'customerSearch.orderRestriction.BIG_INQUIRY': 'Großauskunft',
  'customerSearch.orderRestriction.INFODATA_INQUIRY_9': 'Infodata Auskunft',
  'customerSearch.orderRestriction.ADDRESS_CHECK_MISSING': 'Adressprüfung fehlt',
  'customerSearch.orderRestriction.RESERVATION': 'Reservierung',
  'customerSearch.orderRestriction.PAYMENT_LIMIT_MODAL_TITLE': 'Bitte Bestellung prüfen',
  'customerSearch.orderRestriction.PAYMENT_LIMIT_MODAL_MESSAGE': 'Das Bestelllimit bei {payment_method} ist {amount}. \n\n Bitte versuchen Sie mit einer anderen Zahlungsmethode oder reduzieren Sie die Bestellsumme.',
  'customerSearch.orderRestriction.FREQUENT_RETURNER': 'Hochretournierer',
  'customerSearch.orderRestriction.ACCOUNT_NUMBER_VALIDATED': 'Kontonummer validiert (15)',
  'customerSearch.orderRestriction.DUBIOUS_BANK_ACCOUNTS': 'Dubiose Bankdaten',
  'customerSearch.orderRestriction.NON_PAYER_KSV': 'Nichtzahler KSV',
  'customerSearch.orderRestriction.NON_PAYER_REAL': 'Nichtzahler Real',
  'customerSearch.orderRestriction.NON_PAYER_KSP': 'Nichtzahler KSP',
  'customerSearch.orderRestriction.DEPARTED_CREATOR': 'Creator ausgeschieden',
  'customerSearch.orderRestriction.LS18': 'Mögliche Kundendublette',
  'customerSearch.orderRestriction.LS_INTERN': 'LS intern',
  'customerSearch.orderRestriction.DUPLICATE': 'Dublette/Datenzusammenführung',
  'customerSearch.orderRestriction.PERMANANT_RETURNER': 'Hochretournierer',
  'customerSearch.orderRestriction.NON_PAYERU': 'Nichtzahler',
  'customerSearch.orderRestriction.FRAUD': 'Versandhandelbetrüger',
  'customerSearch.orderRestriction.CUSTOMER_REQUIREMENT': 'Bestellung nicht möglich (Auftragsbeschränkung auf Kundenwunsch) - Kunde kann sich zur Freischaltung schriftlich an HSE24 wenden',
  'customerSearch.orderRestriction.PRANK_ORDER': 'Sperre Juxbesteller',
  'customerSearch.orderRestriction.SAP_LEGACY_DATA': 'Altdaten vor SAP',
  'customerSearch.orderRestriction.DECEASED': 'Verstorben',
  'customerSearch.orderRestriction.UNKNOWN_ADDRESS': 'Adresse unbekannt',
  'customerSearch.orderRestriction.UNDERAGED': 'Minderjährig',
  'customerSearch.orderRestriction.DISABILITY': 'Unmündig',
  'customerSearch.orderRestriction.DRM_LOCK': 'DRM Sperre',
  'customerSearch.orderRestriction.CUSTOMER_TO_BE_DELETED': 'Kunde wird gelöscht',
  'customerSearch.orderRestriction.AS_INTERNAL': 'AS intern',
  'customerSearch.orderRestriction.SUBJECT_TO_LAWYER': 'An Anwalt abgegeben',
  'customerSearch.orderRestriction.INSOLVENCY': 'Raten/Insolvenz/Konkurs',
  'customerSearch.orderRestriction.DEPARTED_EMPLOYEE': 'Mitarbeiter ausgeschieden',
  'customerSearch.orderRestriction.SEE_MASTER_DATA': 's. Bemerkung Stammsatz',
  'customerSearch.orderRestriction.DATA_PRIVACY_LOCK': 'Datenschutzsperrung',
  'customerSearch.orderRestriction.DELETION_GDPR': 'Löschung DSGVO',
  'customerSearch.orderRestriction.TO_BE_ARCHIVED': 'Archivierung geplant',
  'customerSearch.orderRestriction.ARCHIVED': 'Archiviert (EOB)',
  'customerSearch.orderRestriction.NON_PAYER_INFOSCORE': 'sperre Nichtzahler ',
  'customerSearch.orderRestriction.SIEBEL_APPROVED': 'Freigegeben Siebel',
  'customerSearch.orderRestriction.TEMPORARY_DELIVERY_BLOCK': 'Temporäre Liefersperre',

  'order.orderCanceled': 'Die Stornierung wird bearbeitet. Es dauert einen Moment.',

  'order.edit.items': 'Positionen',
  'order.edit.cancellationReason': 'Einen Stornogrund auswählen',
  'order.edit.cancelOrderTitle': 'Die gesamte Bestellung stornieren',
  'order.edit.cancelPositionTitle': 'Position stornieren',
  'order.edit.orderCancelReason': 'Warum möchten Sie die gesamte Bestellung stornieren?',
  'order.edit.positionCancelReason': 'Warum möchten Sie die Produkte stornieren?',
  'order.edit.cancelOrder': 'Bestellung stornieren',
  'order.edit.cancelPosition': 'Position stornieren',
  'order.edit.cancelPosition.txtBtn': 'Stornieren',
  'order.edit.removePosition.txtBtn': 'Löschen',
  'order.edit.positionCancellationReason': 'Stornogrund',
  'order.edit.changeSaved': 'Die Änderung ist gespeichert!',
  'order.edit.changeSavedMsg': 'Die Änderung wird auf Ihrer Bestellung aktualisiert.',
  'order.edit.regards': 'Ich wünsche Ihnen noch einen schönen Tag. Auf Wiederhören!',
  'order.edit.positionCancelled': 'Die Position wurde storniert',
  'order.edit.positionCancelledWithReason': 'Die Position wurde storniert. Grund: {reason}',
  'order.edit.positionReturned' : 'Die Position wurde retourniert',
  'order.edit.orderCancelled': 'Die gesamte Bestellung wurde storniert!',
  'order.edit.orderCancelledMsg': 'Ihrer gesamte Bestellung wurde storniert.',
  'order.edit.productAdded': 'Produkt erfolgreich als neue Position hinzugefügt',

  'order.edit.deliveryStatus.label':'Lieferstatus: ',
  'order.edit.deliveryStatus.value':'In Bearbeitung ({date})',
  'order.edit.deliveryAddress.title':'Lieferadresse: ',
  'order.edit.deliveryAddress.multipleDeliveryPosition':'Lieferadressen stehen in den Positionen',
  'order.edit.deliveryAddress.setPreferred':'Als bevorzugte Lieferadresse markieren',
  'order.edit.deliveryAddress.originalDeliveryAddress':'Identisch mit Rechnungsadresse',
  'order.edit.deliveryAddress.Country.DE':'Deutschland',
  'order.edit.deliveryAddress.Country.AT':'Österreich',
  'order.edit.deliveryAddress.Country.CH':'Schweiz',
  'order.edit.deliveryAddress.Country.undefined':'-',
  'order.edit.deliveryAddress.fromBillingAddress':'Identisch mit Rechnungsadresse',
  'order.edit.deliveryAddress.addNewAddress':'Neue Lieferadresse hinzufügen',
  'order.edit.deliveryAddress.type.POSTAL':'Postanschrift ',
  'order.edit.deliveryAddress.type.PACKSTATION':'Packstation ',
  'order.edit.deliveryAddress.maxAllowedAddressesReached':'Max. Anzahl Lieferadressen erreicht',
  'order.edit.addressNotSaved': 'Diese Lieferadresse wird nicht im Kundenkonto gespeichert',
  'order.edit.postNumber': 'Postnummer',
  'order.edit.packstation': 'Packstation',
  'order.edit.saveChanges' : 'Alle Änderungen speichern',

  'order.edit.DeliveryAddressChangeConfirmation.title': 'Lieferadresse',
  'order.edit.DeliveryAddressChangeConfirmation.description': 'Wenn Sie die Lieferadresse bearbeiten, wird die ausgewählte Lieferadresse für alle Positionen verwendet.',
  'order.edit.DeliveryAddressChangeConfirmation.Abort': 'Abbrechen',
  'order.edit.DeliveryAddressChangeConfirmation.EditDeliveryAddress': 'Lieferadresse bearbeiten',
  'order.edit.cashNotAllowed': 'Nachnahmebestellungen liefern wir ausschließlich an die Rechnungsadresse. Möchten Sie eine abweichende ' +
    'Lieferadresse nutzen, dann wählen Sie bitte eine andere Zahlungsweise aus.',
  'order.edit.installementNotAllowed': 'Wenn Sie die Lieferung an eine Packstation wünschen, dann wählen Sie bitte eine andere Zahlungsart aus.',
  'order.edit.voucher.redeemed': '{VALUE} {CURRENCY} Gutschein {CODE} wird eingelöst',
  'order.edit.remove': 'Löschen',
  'order.edit.redeem': 'Einlösen',
  'order.edit.installmentLimit': 'Ihr Bestellwert erreicht nicht den Mindestbestellwert: 45€ für diese Zahlungsart.',
  'order.edit.installmentFactoringLimit': 'Ihr Bestellwert erreicht nicht den Mindestbestellwert: 49€ für diese Zahlungsart.',
  'order.edit.updateInstallment': 'Wegen des neuen Gesamtbetrags sind die Monatsraten nicht mehr gültig. Klick auf „ändern“, um den Ratenplan zu aktualisieren.',
  'order.edit.installment&VoucherNotAllowed': 'Wenn Sie einen Gutschein einlösen wollen, wählen Sie bitte eine andere Zahlungsart aus.',
  'order.edit.disableVoucherInstallment': 'Bei einer Ratenzahlung kann kein Gutschein eingelöst werden',
  'order.employeeDiscountHint': 'Rabatt wird abgezogen',
  'order.percentageMaxDiscountHint': '. Wir haben für Sie den maximalen Rabattwert von {discount_value} {currency} berechnet.',
  'order.employeeDiscountHint.categoryExcluded': 'Rabatt kann nicht auf {category} abgezogen werden',
  'order.takeBackMessage': '„Wünschen Sie die kostenlose Abholung eines alten Elektrogeräts (mit gleichen Funktionen)?”',
  'order.takeBackCheckBox': 'Alt-Elektrogeräteabholung',
  'order.learnMore': 'Mehr erfahren',

  'order.edit.search.deny.hint':'Bei unterschiedlichen Lieferadressen kann keine neue Position hinzugefügt werden. Bitte neue Bestellung aufnehmen.',
  'order.upgradeMessage': '„Ich empfehle unser Sonderset, bei dem Sie heute {DISCOUNT} sparen? Darin ist auch {NAME} enthalten.“',
  'order.upgradedProduct': 'Upgraded Produkt',
  'order.edit.displayUpgradeVariants': 'Varianten anzeigen',
  'order.edit.upgrade': 'Upgrade',
  'order.itemQuantityWarning': 'Nur {MAX} Stück sind verfügbar. Bitte Kunde informieren und die Menge reduzieren.',
  'order.takeBack.learnMoreTitle': 'Alt-Elektrogeräteabholung',
  'order.takeBack.learnMoreDescription': '„Wir holen Ihr altes Elektrogerät gerne kostenlos bei Ihnen ab, wenn es im Wesentlichen die gleichen Funktionen erfüllt, wie das neue Gerät von HSE. Bestellen Sie z. B. einen Cross-Trainer, können Sie Ihren alten Cross-Trainer entsorgen lassen (nicht aber Ihr Laufband). Die Abholung erfolgt durch die DHL. Weitere Informationen erhalten Sie in den nächsten Tagen telefonisch (oder schriftlich, falls wir Sie nicht erreichen).”',
  'order.takeBack.close': 'Schließen',
  'order.takeBack.detailsCollection': 'Kontaktdaten der Alt-Elektrogeräteabholung',
  'order.takeBack.detailsCollectionHint': '„Weitere Informationen zum Ablauf der Alt-Elektrogeräteabholung erhalten Sie in den nächsten Tagen von uns. Möchten Sie per Telefon oder E-Mail kontaktiert werden?”',
  'order.takeBack.detailsCollectionHintTelephone': '„Weitere Informationen zum Ablauf der Alt-Elektrogeräteabholung erhalten Sie in den nächsten Tagen telefonisch. Bitte nennen Sie mir zum Abgleich Ihre Telefonnummer.”',
  'order.takeBack.detailsCollectionHintEmail': '„Weitere Informationen zum Ablauf der Alt-Elektrogeräteabholung erhalten Sie in den nächsten Tagen per E-Mail. Bitte nennen Sie mir zum Abgleich Ihre E-Mail-Adresse.”',
  'order.takeBack.detailsCollectionEmailChangeHint': 'Falls E-Mail-Adresse veraltet oder falsch, Kund*in bitte im Anschluss an die Bestellung an die Serviceline verweisen (Tel.: 0800 23 414 74)',
  'order.takeBack.confirmEditedOrder': 'Bestätigen und alle Änderungen speichern',
  'order.takeBack.confirmOrder': 'Bestätigen und Auftrag abschließen',

  'order.subscriptionSellable.notSupported':'Bitte wechseln Sie für die Anlage eines Treue Abo-Produktes in KIK',
  'order.check.redeem': 'Eingelöste Gutscheine prüfen',

  'order.discountHint': 'Gutschein wird eingelöst',
  'order.edit.payment.edit.blocked': 'Wartungsarbeiten. Zahlungsartenwechsel in Ratenzahlung derzeit nicht möglich.',

  'order.unsellableOrder.reasons.VARIANT_NOT_AVAILABLE': 'Dieses Produkt ist leider ausverkauft. Bitte Kunde informieren und das Produkt löschen.',
  'order.unsellableOrder.reasons.SUBSCRIPTION_ONLY': 'Dieses Produkt ist nur als Treue-Abo erhältlich.',
  'order.unsellableOrder.reasons.ERP_PRODUCT_NOT_IN_SORTIMENT': 'Dieses Produkt ist derzeit nicht verfügbar.',
  'order.unsellableOrder.reasons.ERP_PRODUCT_NOT_RELEASED': 'Dieses Produkt ist derzeit nicht verfügbar.',
  'order.unsellableOrder.reasons.OUT_OF_STOCK': 'Dieses Produkt ist leider ausverkauft. Bitte Kunde informieren und das Produkt löschen.',
  'order.unsellableOrder.reasons.AVAILABLE_QUANTITY_EXCEEDED': 'Nur {MAX} Stück sind verfügbar. Bitte Kunde informieren und die Menge reduzieren.',
  'order.unsellableOrder.reasons.PRICE_DATE_NOT_VALID': 'Preisdatum ist nicht gültig.',
  'order.unsellableOrder.reasons.NOT_AVAILABLE_IN_COUNTRY': 'Dieses Produkt ist im ausgewählten Land nicht erhältlich.',
  'order.unsellableOrder.reasons.NOT_RESERVED': 'Dieses Produkt ist leider ausverkauft. Bitte Kunde informieren und das Produkt löschen.',
  'orderConfirmation.effectiveInterestRate': 'Effektivzins {interestRate}%',
  'orderConfirmation.nominalInterestRate': 'Nominalzins {nominalInterestRate}%',
  'orderConfirmation.fundingAmount': 'Finanzierungsbetrag',

  'order.invalidInstallmentsPlans': 'Wegen des neuen Gesamtbetrags sind die Monatsraten nicht mehr gültig. Klicke auf "{paymentLabel}", um den Ratenplan zu aktualisieren.',
};

import {
  call,
  put,
  select,
  takeLatest,
} from 'redux-saga/effects';

import { fetchCustomerSuccess } from 'src/redux/customer/customerInfoSlice';
import { checkAndLoadLocalCustomerInfo } from 'src/sagas/customer/fetchCustomer';
import { findCustomerByExternalId } from 'src/sagas/customer/fetchCustomerByNumberSaga';
import { buildInvoiceAddressFromCustomer } from 'src/sagas/utils/buildOfferRequest';
import { AddressType, DeliveryAddressType } from 'src/types/customer/address';
import { Customer } from 'src/types/customer/customer';
import { PhoneNumberType } from 'src/types/customer/phoneNumber';
import { SubscriptionProductResponse } from 'src/types/subscription/SubscriptionsOverviewResponse';
import { containsSimilarAddress } from 'src/utils/formatters/formatAddressToString';
import logErrorAndShowNotification from 'src/utils/logErrorAndShowNotification';
import { reformatBirthdayInResponse } from 'src/utils/mappers/reformatBirthdayInResponse';

import { subscriptionManagementSelectors } from '../redux/selectors/subscriptionManagementSelectors';
import { loadCurrentSubscriptionCustomer } from '../redux/subscriptionManagementSlice';


export function* loadCurrentSubscriptionCustomerSaga() {
  const currentSubscription: SubscriptionProductResponse = yield select(
    subscriptionManagementSelectors.getCurrentSubscriptionDetails
  );
  try {
    const externalCustomerId = currentSubscription.externalCustomerId;
    const customer =
      externalCustomerId !== ''
        ? yield call(findCustomerByExternalId, externalCustomerId)
        : buildCustomerFromSubscription(currentSubscription);

    const enrichedCustomer = yield call(checkAndLoadLocalCustomerInfo, customer);

    yield put(fetchCustomerSuccess(reformatBirthdayInResponse(enrichedCustomer)));
  } catch (err) {
    yield call(
      logErrorAndShowNotification,
      `Couldn't load customer data ${currentSubscription.externalCustomerId} of subscription ${currentSubscription.contractNumber}`,
      'Hoppla... Kundeninformationen dieser Abonnements konnten nicht geladen werden.',
      err
    );
  }
}

const buildCustomerFromSubscription = (subscription: SubscriptionProductResponse): Customer => {
  const customer = subscription.details.customer;
  const builtCustomer: Customer = {
    deliveryAddresses: [subscription.details.deliveryAddress],
    externalCustomerId: '',
    firstName: customer.firstName,
    id: '',
    lastName: customer.lastName,
    origin: subscription.details.salesChannel,
    phoneNumbers: customer.phone
      ? [{ phoneNumberType: PhoneNumberType.PRIVATE, value: customer.phone }]
      : [],
    salutation: customer.salutation,
    dateOfBirth: customer.dateOfBirth,
    email: customer.email,
    billingAddress: {
      city: customer.city,
      countryCode: customer.countryCode,
      street: customer.street,
      streetNumber: customer.streetNumber,
      zipCode: customer.zipCode,
      addressAddition: customer.addressAddition,
      careOf: customer.careOf,
      id: customer.dplCustomerId,
      type: AddressType.Billing,
    },
    phoneNumber: customer.phone,
    bankingDetails: subscription.details.payment.iban ? {
      accountHolder: subscription.details.payment.accountHolder ?? '-',
      bankName: '-',
      maskedIban: subscription.details.payment.iban,
    }
      : undefined,
  };

  const invoiceAddress = buildInvoiceAddressFromCustomer(builtCustomer);

  if (invoiceAddress && !containsSimilarAddress(builtCustomer.deliveryAddresses, invoiceAddress)) {
    builtCustomer.deliveryAddresses.unshift(invoiceAddress);
  } else {
    if (subscription.details.deliveryAddress.type === DeliveryAddressType.Postal) {
      builtCustomer.deliveryAddresses = [
        { ...subscription.details.deliveryAddress, fromInvoiceAddress: true },
      ];
    }
  }

  return builtCustomer;
};

export default function* loadCurrentSubscriptionCustomerWatcher() {
  yield takeLatest(loadCurrentSubscriptionCustomer.type, loadCurrentSubscriptionCustomerSaga);
}

import { SnackbarKey, useSnackbar } from 'notistack';
import {
  FC,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import NotificationCloseIcon from 'src/components/shared/Icons/NotificationCloseIcon';
import useOutsideClick from 'src/hooks/useOutsideClick';
import { logEvent } from 'src/logging/loggingActions';
import { LOG_LEVEL } from 'src/logging/loggingService';
import { dismissSnackNotification } from 'src/redux/app/appSlice';
import { appSelectors } from 'src/redux/app/selectors/appSelector';
import { orderEntrySelector } from 'src/redux/order/orderEntrySlice/selectors/orderEntrySelectors';

import styles from './NotificationProvider.module.scss';


interface Props {
  children: ReactElement;
}

const NUMBER_NOTIFICATIONS_TO_DISPLAY = 1;

const NotificationProvider: FC<Props> = (props: Props) => {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const thisRef = useRef<HTMLDivElement>(null);
  const anActionInProgress = useSelector(orderEntrySelector.isThereAnActionInProgress);
  const notifications = useSelector(appSelectors.getSnackNotifications);
  const areThereNotifications = notifications.length > 0;
  const [activeSnackbarKeys, setSnackbarKeys] = useState<SnackbarKey[]>([]);

  useEffect(() => {
    if (areThereNotifications && !anActionInProgress && activeSnackbarKeys.length < NUMBER_NOTIFICATIONS_TO_DISPLAY) {
      const notificationsToDisplay = notifications.slice(-NUMBER_NOTIFICATIONS_TO_DISPLAY);
      const lastNotification = notificationsToDisplay.slice(-1);
      lastNotification.forEach(notification => {
        const onCloseSnackbar = (key: number, notificationId: string) => {
          dispatch(dismissSnackNotification(notificationId));
          closeSnackbar(key);
        };
        const enqueueSnackbarAction = (): SnackbarKey => enqueueSnackbar(
          formatMessage({ id: notification.translationKey, defaultMessage: notification.fallbackMessage }),
          {
            variant: notification.variant,
            anchorOrigin: { vertical: 'top', horizontal: 'center' },
            autoHideDuration: null,
            preventDuplicate: true,
            action: (key: number) => (
              <div className={styles.button_root} onClick={() => onCloseSnackbar(key, notification.id)}>
                <NotificationCloseIcon />
              </div>
            ),
            onExit: () => dispatch(dismissSnackNotification(notification.id)),
          }
        );
        if (activeSnackbarKeys.length < NUMBER_NOTIFICATIONS_TO_DISPLAY) {
          setSnackbarKeys([...activeSnackbarKeys, enqueueSnackbarAction()]);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [areThereNotifications, anActionInProgress, notifications.length]);

  useOutsideClick(
    thisRef,
    () => {
      closeSnackbar();
      setSnackbarKeys([]);
    },
    activeSnackbarKeys.length > 0
  );

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const logError = (error: Error, stackError: any) => {
    dispatch(
      logEvent({
        level: LOG_LEVEL.ERROR,
        message: `Error during rendering the view: errorName:${error.name}, errorMessage:${error.message}, location:${window?.location?.href}`,
        err: stackError,
      }),
    );
  };

  return (
    <ErrorBoundary onError={logError} fallback={<p>{formatMessage({ id: 'errors.general' })}</p>}>
      <>{<span ref={thisRef}></span>}</>
      {props.children}
    </ErrorBoundary>
  );
};

export default NotificationProvider;

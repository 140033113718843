import {
  createAction,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';
import { v4 as uuid } from 'uuid';

import { AuthorizationCodeResponse } from 'src/api/authTypes';
import { CountryCode } from 'src/api/orderTypes';
import { LocalStorageKeys } from 'src/constants/localStorageKeys';
import { Modals } from 'src/types/Modals';
import countryLocalStorage from 'src/utils/countryLocalStorage';


export enum AppActionTypes {
  SET_AGENT_INFO = 'app/SET_AGENT_INFO',
  LOAD_AUTHENTICATED_AGENT_INFO = 'app/LOAD_AUTHENTICATED_AGENT_INFO',
  CHECK_AUTHENTICATED_AGENT_TOKEN = 'app/CHECK_AUTHENTICATED_AGENT_TOKEN',
  SHOW_NOTIFICATION = 'app/SHOW_NOTIFICATION',
  SHOW_SNACK_NOTIFICATION = 'app/SHOW_SNACK_NOTIFICATION',
  FETCH_OAUTH_TOKENS = 'app/FETCH_OAUTH_TOKENS',
  NEW_SESSION = 'session/NEW_SESSION',
  NEW_SESSION_WITH_CUSTOMER = 'session/NEW_SESSION_WITH_CUSTOMER',
  NEW_SESSION_WITH_BASKET = 'session/NEW_SESSION_WITH_BASKET',
  OPEN_MODAL = 'modal/openModal',
  CLOSE_MODAL = 'modal/closeModal',
  CLOSE_ALL_MODALS = 'modal/CLOSE_ALL_MODALS',
  TRACK_USER_LOGIN_EVENT = 'app/TRACK_USER_LOGIN_EVENT',
  TRACK_USER_FAILURE_LOGINS_EVENT = 'app/TRACK_USER_FAILURE_LOGINS_EVENT',
  TRACK_USER_SESSION_EVENT = 'app/TRACK_USER_SESSION_EVENT'
}

export type FetchOauthTokens = {
  type: AppActionTypes.FETCH_OAUTH_TOKENS;
  payload: string;
};

export enum LanguageOptions {
  en = 'en',
  de = 'de'
}

export type NotificationVariant = 'error' | 'info' | 'success' | 'warning';

export interface AppState {
  loading: boolean;
  language: LanguageOptions;
  country: CountryCode;
  notifications: Notification[];
  snackNotifications: Notification[];
  idToken: string | null;
  authError: boolean;
  refreshToken: string | null;
  session: {
    loading: boolean;
    error?: Error;
  };
  modal: {
    opened: Modals[];
  };
  agentInfo?: AgentInfo;
  loginMethod?: LoginMethod
}

export interface AgentInfo {
  username: string;
  fullName: string;
  roles: string[];
}

export interface Notification {
  variant: NotificationVariant;
  translationKey: string;
  fallbackMessage: string;
  durationInSeconds?: number;
  id: string;
}

export const appInitialState: AppState = {
  language: localStorage.getItem(LocalStorageKeys.Language) as LanguageOptions || LanguageOptions.de,
  country: countryLocalStorage.get() || CountryCode.de,
  loading: false,
  authError: false,
  notifications: [],
  snackNotifications: [],
  idToken: localStorage.getItem(LocalStorageKeys.IdToken),
  refreshToken: localStorage.getItem(LocalStorageKeys.RefreshToken),
  session: {
    loading: false,
  },
  modal: {
    opened: [],
  },
};
export enum LoginMethod{
  ADVANCED_CUSTOMER_SEARCH = 'advanced_customer_search',
  CUSTOMER_NUMBER = 'customer_number',
  LAST_CUSTOMER_AUTHENTICATION = 'last_customer_authentication',
  LOAD_EXISTING_ORDER_CUSTOMER = 'load_existing_order_customer'
}

export enum ErrorContext{
  LOGIN= 'login'
}
export interface LoginEventPayload{
  c_agentId?: string,
  loginMethod?: LoginMethod,
  loginVerificationOption?: string,
}

export interface FailureLoginEventPayload extends  LoginEventPayload{
  errorMessage: string,
  errorContext: ErrorContext
}

export type TrackUserLoginEvent = {
  type: AppActionTypes.TRACK_USER_LOGIN_EVENT;
  payload: LoginEventPayload;
};

export type TrackUserFailureLoginsEvent = {
  type: AppActionTypes.TRACK_USER_FAILURE_LOGINS_EVENT;
  payload: FailureLoginEventPayload;
};

export type TrackUserSessionEvent = {
  type: AppActionTypes.TRACK_USER_SESSION_EVENT;
  payload: string;
};

export const fetchOauthTokens = createAction<string>(AppActionTypes.FETCH_OAUTH_TOKENS);
export const newSession = createAction(AppActionTypes.NEW_SESSION);
export const newSessionWithCustomer = createAction(AppActionTypes.NEW_SESSION_WITH_CUSTOMER);
export const newSessionWithBasket = createAction(AppActionTypes.NEW_SESSION_WITH_BASKET);
export const trackUserLoginEvent = createAction<LoginEventPayload>(AppActionTypes.TRACK_USER_LOGIN_EVENT);
export const trackUserFailureLoginsEvent = createAction<FailureLoginEventPayload>(AppActionTypes.TRACK_USER_FAILURE_LOGINS_EVENT);
export const trackUserSessionEvent = createAction<string>(AppActionTypes.TRACK_USER_SESSION_EVENT);
export const showNotification = createAction<Notification>(AppActionTypes.SHOW_NOTIFICATION);
export const openModal = createAction<Modals>(AppActionTypes.OPEN_MODAL);
export const closeModal = createAction<Modals>(AppActionTypes.CLOSE_MODAL);
export const closeAllModals = createAction(AppActionTypes.CLOSE_ALL_MODALS);
export const setAgentInfo = createAction<AgentInfo>(AppActionTypes.SET_AGENT_INFO);
export const loadAuthenticatedAgentInfo = createAction(AppActionTypes.LOAD_AUTHENTICATED_AGENT_INFO);
export const checkAuthToken = createAction(AppActionTypes.CHECK_AUTHENTICATED_AGENT_TOKEN);

const appSlice = createSlice({
  name: 'app',
  initialState: appInitialState,
  reducers: {
    setLanguage(state, { payload }: PayloadAction<LanguageOptions>) {
      state.language = payload;
    },
    setCountry(state, { payload }: PayloadAction<CountryCode>) {
      state.country = payload;
    },
    changeDropDownCountry(state, { payload }: PayloadAction<CountryCode>) {
      state.country = payload;
    },
    setCountryState(state, { payload }: PayloadAction<CountryCode>) {
      state.country = payload;
    },
    setLoginMethod(state, { payload }: PayloadAction<LoginMethod>) {
      state.loginMethod = payload;
    },
    dismissNotification(state, { payload }: PayloadAction<number>) {
      const notification = JSON.parse(JSON.stringify(state.notifications));
      notification.splice(payload, 1);
      state.notifications = notification;
    },
    dismissSnackNotification(state, { payload }: PayloadAction<string>) {
      state.snackNotifications = state.snackNotifications.filter(item => item.id !== payload);
    },
    fetchOauthTokensSuccess(state, { payload }: PayloadAction<AuthorizationCodeResponse>) {
      state.authError = false;
      state.idToken = payload.idToken;
      state.refreshToken = payload.refreshToken;
    },
    fetchOauthTokensFailed(state) {
      state.authError = true;
      state.idToken = null;
      state.refreshToken = null;
    },
    showNotification(state, { payload }: PayloadAction<Notification>) {
      state.notifications = state.notifications.concat(payload);
    },
    showErrorNotification(state, { payload }: PayloadAction<string|undefined>) {
      state.snackNotifications = state.snackNotifications.concat({
        variant: 'error',
        translationKey: payload || 'errors.general',
        fallbackMessage: payload || 'errors.general',
        id: uuid(),
      });
    },
    showSuccessNotification(state, { payload }: PayloadAction<string>) {
      state.snackNotifications = state.snackNotifications.concat({
        variant: 'success',
        translationKey: payload,
        fallbackMessage: payload,
        durationInSeconds: 3,
        id: uuid(),
      });
    },
    showInfoNotification(state, { payload }: PayloadAction<string>) {
      state.snackNotifications = state.snackNotifications.concat({
        variant: 'info',
        translationKey: payload,
        fallbackMessage: payload,
        id: uuid(),
      });
    },
    clearSnackNotifications(state) {
      state.snackNotifications = [];
    },

  },
  extraReducers: builder => {
    builder
      .addCase( openModal,(state, { payload }: PayloadAction<Modals>) => {
        state.modal.opened = [...state.modal.opened, payload];
      })
      .addCase( closeModal,(state, { payload }: PayloadAction<Modals>) => {
        state.modal.opened = state.modal.opened.filter(modal => modal !== payload);
      })
      .addCase( closeAllModals,(state) => {
        state.modal.opened = [];
      })
      .addCase( showNotification,(state, action: PayloadAction<Notification>) => {
        state.notifications = state.notifications.concat(action.payload);
      })
      .addCase( setAgentInfo,(state, action: PayloadAction<AgentInfo>) => {
        state.agentInfo = action.payload;
      });
  },
},
);

export const {
  setLanguage,
  setCountry,
  setCountryState,
  dismissNotification,
  dismissSnackNotification,
  fetchOauthTokensSuccess,
  fetchOauthTokensFailed,
  showErrorNotification,
  showSuccessNotification,
  showInfoNotification,
  changeDropDownCountry,
  clearSnackNotifications,
  setLoginMethod
} = appSlice.actions;

export default appSlice.reducer;

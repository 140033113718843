import { DeliveryAddress } from 'src/types/customer/address';
import { OfferItem } from 'src/types/offer/Offer';
import { InstallmentPlan, Payment } from 'src/types/offer/Payment';
import { PaymentOption } from 'src/types/offer/PaymentOption';
import { OrderHistoryResponse } from 'src/types/orderhistory/OrderHistoryResponse';
import { Currency } from 'src/types/product/Currency';
import { EmployeeDiscount } from 'src/types/voucher/EmployeeDiscount';
import { VoucherResponse } from 'src/types/voucher/VoucherResponse';


export interface EditedOrder {
  originalOrder: OrderHistoryResponse;
  editedOffer: EditedOffer;
}

export interface EditedOffer {
  editedOrderId: string;
  items: OfferItem[];
  totalProductPrice: number;
  totalPrice: number;
  shippingCost: {
    amount: number;
  };
  totalSavings: number;
  deliveryAddress?: DeliveryAddress;
  currency: Currency;
  payment?: Payment;
  voucher?: VoucherResponse;
  isShippingCostOverwritten?: boolean;
  paymentOptions?: PaymentOption[];
  paymentCheckResult?: PaymentCheckResult;
  installmentPlans?: InstallmentPlan[];
  changes?: EditOrderChange[];
  employeeDiscount?: EmployeeDiscount;
  canUsePackstationAddress?: boolean;
}

export interface PaymentCheckResult {
  reason: PaymentCheckResultReason
}
export enum PaymentCheckResultReason {
  ADDRESS_CAT_2 = 'ADDRESS_CAT_2',
  NEW_CUSTOMER_ADDRESS_CAT_4 = 'NEW_CUSTOMER_ADDRESS_CAT_4',
  CUSTOMER_DUNNING_LEVEL_2_OR_GREATER = 'CUSTOMER_DUNNING_LEVEL_2_OR_GREATER',
  CUSTOMER_INSTALLMENT_DUNNING_LEVEL_2_OR_GREATER = 'CUSTOMER_INSTALLMENT_DUNNING_LEVEL_2_OR_GREATER',
  ONGOING_MAJOR_REPORT = 'ONGOING_MAJOR_REPORT',
  CUSTOMER_5D__HIGH_RETURN_RATE__LAST_ID_ZERO = 'CUSTOMER_5D__HIGH_RETURN_RATE__LAST_ID_ZERO',
  CUSTOMER_9N_9S__HIGH_ORDER_VALUE = 'CUSTOMER_9N_9S__HIGH_ORDER_VALUE',
  NO_ORDER_VALUE = 'NO_ORDER_VALUE',
  EXTERNAL_CHECK = 'EXTERNAL_CHECK'
}

export enum EditOrderChangeType {
  ADD_POSITION = 'ADD_POSITION',
  REMOVE_POSITION = 'REMOVE_POSITION',
  UPDATE_POSITION = 'UPDATE_POSITION',
  CANCEL_POSITION = 'CANCEL_POSITION',
  SWITCH_DELIVERY_ADDRESS = 'SWITCH_DELIVERY_ADDRESS',
  CHANGE_PAYMENT = 'CHANGE_PAYMENT',
  REDEEM_VOUCHER = 'REDEEM_VOUCHER',
}
export interface EditOrderChange {
  type: EditOrderChangeType;
  sku: string;
  quantity?: number;
  reason?: string;
  address?: DeliveryAddress;
  id?: string;
  code?: string;
  payment?: EditOrderPaymentChange;
}

interface EditOrderPaymentChange {
  method: string;
}

